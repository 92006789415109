import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { MenuIcon, TwitterIcon, DiscordIcon, SettingIcon } from "../../icons";
import { bsc } from 'wagmi/chains'
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

import Swal from 'sweetalert2';



import { FiLogOut } from "react-icons/fi"; // import exit icon from react-icons library

const Header = (props) => {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector({
      bsc,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  });
  const { disconnect } = useDisconnect();

  const accountEllipsis = address ? `${address.substring(0, 4)}...${address.substring(address.length - 4)}` : null;

  const handleConnectClick = () => {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_chainId' }).then(chainId => {
        if (chainId === '0x38') {
          connect();
        } else {
          Swal.fire({
            title: 'Connect to Binance Smart Chain',
            text: 'Please connect to Binance Smart Chain to use this application',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel'
          });
        }
      });
    } else {
      Swal.fire({
        title: 'Open in Wallet Browser',
        text: 'Please open this link in Wallet Browser',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      });
    }
  }

  const handleDisconnectClick = () => {
    disconnect();
  }

  return (
    <div className="header-camp flex">
      <div className="wrapWidth wrap flex items-center">
        <div className="left flex items-center">
          <Link to="/" className="logo">
            <img src="https://sitbackrelax.finance/img/logo.jpg" style={{maxWidth: '100%'}} />
          </Link>
        </div>
        <div className="right flex items-center">
          <div className="action flex items-center">
          {isConnected ? (
            <div className="flex items-center">
              <div className="btn button-85 mr-2">
                { accountEllipsis }
              </div>
              <div className="btn button-85" onClick={handleDisconnectClick}>
                <FiLogOut size={10} /> {/* replace "Disconnect" text with exit icon */}
              </div>
            </div>
          ) : (
            <div className="btn button-85" onClick={handleConnectClick}>
              Connect
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default Header;
