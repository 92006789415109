import "./Button.scss";

const Footer = ()=>{
    return(
        <div className="heading">
            <h1 className="h1-heading">Risk Warning</h1>
          <div className="headline">
              
                    <p>Investment in financial instruments involves a high degree of risk. 
                        As such, they may not be suitable for all investors. 
                        Investors should ensure they fully understand the risks associated with investing in financial 
                        instruments before deciding to invest as they may lose partial or all invested capital. </p><br/>
                    <p>The information contained in this website is for general information purposes 
                        only and should not be viewed as offering any personal investment service. 
                        Investors may choose to seek independent advice and should not risk more than they are prepared to lose. <br/>
                        We make no representations or warranties of any kind, express or implied, about the completeness,
                         accuracy, reliability, suitability, appropriateness or availability with respect to the website or the information,
                          products, services, or related graphics contained on the website for any purpose. 
                          Any reliance placed on such information is therefore strictly at the investors’ own risk.<br/>
                          PAST PERFORMANCE IS NO GUARANTEE OF FUTURE RETURNS. 
                          In no event will we be liable for any loss or damage including without limitation, 
                          indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss 
                          of data or profits arising out of, or in connection with, the use of this website.
                           </p>
                        
          </div>
           
        </div>
    )
}

export default Footer;