import { StackedBarChartTwoTone } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react'
import StakeOne from './stakeone';
import StakeTwo from './staketwo';
import StakeThree from './stakethree';
// import StakeFour from './stakefour';
import Button from './Button';
import Footer from './footer';

const Main = (props) => {

  return (
    <div>
      <Button/>
      <div className="home-page flex flex-col">

        <div className="wrap wrapWidth flex justify-center flex-col items-center">
          <div className="_block flex">
            <StakeOne />
            <StakeTwo />
            <StakeThree />
            {/* <StakeFour /> */}
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
};

export default Main;
