import "./Button.scss";

//https://pancakeswap.finance/swap?outputCurrency=0x22A64572863E8B935444f4b84fc1C2314C041b10
const Button = () =>{
    return(
        <div>
            <div className="heading">
                <h1 className="top-heading">SitBackRelax Finance</h1>
                    <h1 className="best-looks" style={{justifyContent:"center" , textAlign:"center"}}>SBR Finance was visioned with one purpose, to bring back the trust of the BSC community and build the best decentralized ecosystem available to the world and make an impact on the vision of holders!</h1><br/>
                    <a style={{paddingTop:"40px"}} href="https://pancakeswap.finance/swap?outputCurrency=0x4949c08dD952b6f6A4dcf00aC7C4f27d971ec95f" target="_blank">
                        <button  className="button-85" >Buy Now</button>
                    </a>
            </div>
     
        </div>
    )
}

export default Button;